export default class Movie {
    constructor() {
        this.init();
    }

    init() {
        this.playMov();
    }

    //低電力モード対策：低電力モードの時は背景画像が見えるように
    playMov() {
        const topVideo = document.getElementById('topvideo');

        window.addEventListener('load', () => {
            topVideo.play();
        });
    }
}