import Swiper, { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

export default class Banner {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        const option = {
            modules: [Pagination],
            autoplay: { delay: 5000 },
            loop: true,
            pagination: { el: '.swiper-pagination', clickable: true },
        };

        const swiper = new Swiper('.js-mvbanner-swiper', option);
    }
}
